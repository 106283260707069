import { useSelector } from 'react-redux';
import Modal from '../modal/Modal'
import styles from './chat.module.scss'
import { AiOutlineClose,AiOutlinePaperClip  } from 'react-icons/ai'
import * as proto from '../../protobuf/v1/proto_pb'
import React, { useEffect, useRef, useState } from 'react';
import { Response } from '../../protobuf/v1/proto_pb';
import { IoIosArrowRoundForward } from "react-icons/io";
import { formatTime, isPersianOrArabic, sendingChatMessage, sendingDeliveredMessage, sendingSeenMessage, sendIsTypingMessage } from '../../utils/helperFunctions';
import { CiTimer } from "react-icons/ci";
import { FaCheck } from "react-icons/fa6";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { getReq, HttpPostReq } from '../../services/requests';
import IsTyping from '../isTyping/IsTyping';
import { CHAT_ACTIONS } from '../../utils/Constants';
import FilePreview from '../filePreview/FilePreview';
import TextMessage from '../messagestype/text/TextMessage';
import PhotoMessage from '../messagestype/photo/PhotoMessage';
import VideoMessage from '../messagestype/video/VideoMessage';
import FileDownloading from '../fileDownloadmodal/FileDownloading';
import VoiceMessage from '../voice/VoiceMessage';
export const messageStatus = (status) => {
  switch (status) {
    case 'pending':
      return <CiTimer />
    case 'sent':
      return <FaCheck />
    case 'delivered':
      return <><IoCheckmarkDoneSharp /></>
    case 'seen':
      return <><IoCheckmarkDoneSharp color='lightblue' /></>
    default:
      return <CiTimer />
  }
}

const ChatArea = ({ isOpen, closeModal, callManager, setIsModalOpen }) => {
  const { partner_account_id, accountId, accessToken} = useSelector(state => state.auth);
  const [messages, setMessages] = useState([]);
  const [lastMessage, setLastMessage] = useState(messages[messages.length - 1]);
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [input, setInput] = useState('');
  const [serverTimeStamp, setServerTimeStamp] = useState(0);
  const [firsAppConfigTime, setFirsAppConfigTime] = useState(0);
  const [partnerIsTyping, setPartnerIsTyping] = useState(false);
  const [myIsTyping, setMyIsTyping] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreviewModalOpen, setFilePreviewModalOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState('');
  const lastMessageRef = useRef(null);
  const typingTimeoutRef = useRef(null);
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const handleFileChange = (e) => {

    const file = e.target.files[0];
    if (file && (file.type.startsWith('image/') || file.type === 'video/mp4' || file.type === 'video/mov' || file.type === 'video/quicktime' || file.type.startsWith('audio/') || file.type==='.mp3'  )) {
      // console.log('File accepted:', file.name);
      setSelectedFile(file);
      setFilePreviewModalOpen(true); 
      e.target.value = '';
    } else {
      alert('Please upload a valid image or MP4 video file.');
      e.target.value = ''; 
    }};

  const closeFilePreviewModal = () => {
    setFilePreviewModalOpen(false);
    setSelectedFile(null); 
  };
  const handleInputChange = (e) => {
    setInput(e.target.value)
    handleTypingStatus()
  }
  const handleTypingStatus = () => {
    if (!myIsTyping) {
      setMyIsTyping(true);
      const isTypingBase64 = sendIsTypingMessage(accountId, partner_account_id, true)
      callManager.sendMessage(isTypingBase64)
    }
    clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      setMyIsTyping(false);
      const isTypingBase64 = sendIsTypingMessage(accountId, partner_account_id, false)
      callManager.sendMessage(isTypingBase64)
    }, 2000);
  }
  useEffect(() => {
    if (isFirstRender) {
      callManager.CreateCoreWsConnecton(accessToken)
      const getWebSocketMessage = (responseType) => {
        const metaMessage = new proto.Meta();
        // Step 1: Decode Base64 to binary
        const binaryString = atob(responseType);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        // Step 2: Deserialize the binary buffer
        const deserializedMessage = Response.deserializeBinary(bytes).toObject();
        // Now you can use deserializedMessage
        // console.log('Deserialized message obj66666:', deserializedMessage.chat);
        if (deserializedMessage.action === CHAT_ACTIONS.CHAT_ACK) {
          setMessages(prevMessages =>
            prevMessages.map(message =>
              message.uuid === deserializedMessage.chatAck.uuid ? { ...message, status: 'sent' } : message
            )
          );
        }
        if (deserializedMessage.action === CHAT_ACTIONS.CHAT && deserializedMessage.chat.topic.chatTypeMessage === 1) {
          setIsModalOpen(true)
          const messagesData = JSON.parse(deserializedMessage.chat.data);
          // console.log('json object777',messagesData);
         
          if (messagesData.chatType==='MESSAGE') {
            setMessages(prevMessages => {
              const newMessages = [...prevMessages, {type:'TEXT', text: messagesData.msg, sender: 'partner', timestamp: messagesData.timestamp, showTime: formatTime(messagesData.timestamp), uuid: messagesData.uuid }];
              return newMessages.sort((a, b) => a.timestamp - b.timestamp);
            })
          }
          if (messagesData.chatType==='PHOTO' || messagesData.chatType==='VIDEO') {
            HttpPostReq('/v1/presigned-url-download',{
              file_name:messagesData.mediaFiles[0].mediaFileKey,
              thumbnail_name:messagesData.mediaFiles[0].thumbnailFileKey
            }).then(res=>{
             
              setMessages(prevMessages => {
                const newMessages = [...prevMessages, {type:messagesData.chatType, text: messagesData.mediaFiles[0].caption, sender: 'partner', timestamp: messagesData.timestamp, showTime: formatTime(messagesData.timestamp), uuid: messagesData.uuid,mediaUrl:res.data.file_url }];
                return newMessages.sort((a, b) => a.timestamp - b.timestamp);
              })
              
            }).catch(err=>{
              console.log(err.message);
              
            })
            // console.log('thumnail key',messagesData.mediaFiles[0].thumbnailFileKey);
            // console.log('json media key',messagesData.mediaFiles[0].mediaFileKey);

          }
         
          setPartnerIsTyping(false)
          const deliveredBase64 = sendingDeliveredMessage(accountId, partner_account_id, messagesData.uuid)
          callManager.sendMessage(deliveredBase64)
          const seenBase64 = sendingSeenMessage(accountId, partner_account_id, messagesData.timestamp)
          callManager.sendMessage(seenBase64)
          // console.log('messages', messages);
        }
        if (deserializedMessage.action === CHAT_ACTIONS.CHAT  && deserializedMessage.chat.topic.chatTypeMessage === 2) {
          const messagesData = JSON.parse(deserializedMessage.chat.data);
          setMessages(prevMessages =>
            prevMessages.map(message =>
              messagesData.uuids.includes(message.uuid) ? { ...message, status: 'delivered' } : message
            )
          );

        }
        if (deserializedMessage.action ===CHAT_ACTIONS.CHAT  && deserializedMessage.chat.topic.chatTypeMessage === 3) {
          const messagesData = JSON.parse(deserializedMessage.chat.data);
          // console.log('ffff', messagesData);
          setMessages(prevMessages =>
            prevMessages.map(message =>
              messagesData.timestamp >= message.timestamp ? { ...message, status: 'seen' } : message
            )
          );
        }
        if (deserializedMessage.action === CHAT_ACTIONS.CHAT  && deserializedMessage.chat.topic.chatTypeMessage === 5) {
          const messagesData = JSON.parse(deserializedMessage.chat.data);
          setPartnerIsTyping(messagesData.status)
        }
      };
      // callManager.setStatusCallback(setWsStatus);
      callManager.addListenerToCoreWebSocket(getWebSocketMessage)
      setIsFirstRender(false);
    }
  }, []);

  //first, we need to get server time stamp 
  useEffect(() => {
    const fetchAppConfig = () => {
      getReq('/v1/app-config').then(res => {
        setServerTimeStamp(res.data.current_timestamp * 1000)
        setFirsAppConfigTime(Date.now())
      }).catch(err => {
        setServerTimeStamp(Date.now())
        setFirsAppConfigTime(Date.now())
      });
    };

    if (isFirstRender) {
      fetchAppConfig()
      setIsFirstRender(false);
    }

  }, [isFirstRender])
  //----handling  clt+enter  to send new message
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === 'Enter') {
        e.preventDefault();
        sendMessage();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [input])
  const sendMessage = () => {
    const messageBase64 = sendingChatMessage(accountId, partner_account_id, firsAppConfigTime, serverTimeStamp, input, setMessages)
    callManager.sendMessage(messageBase64)
      setInput('')
    inputRef.current.focus();
  }
  // Use effect to update the last message each time the messages state changes
  useEffect(() => {
    setLastMessage(messages[messages.length - 1]);
  }, [messages]);
  //focus on input when modal is opened
  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus()     
    }
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isOpen]);
  // Use effect to scroll to the last message when it changes
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [lastMessage, partnerIsTyping]);
  //rendering chat messages based on type
  const renderMessage = (msg) => {
    // console.log('msa',msg);
    
    switch (msg.type) {
      case 'TEXT':
        return <TextMessage msg={msg}  />;
      case 'PHOTO':
        return <PhotoMessage msg={msg} uploadProgress={uploadProgress} />;
      case 'VIDEO':
        return <VideoMessage msg={msg} uploadProgress={uploadProgress} />;
      case 'VOICE':
        return <VoiceMessage msg={msg} uploadProgress={uploadProgress}/>;
      default:
        return null;
    }
  }
  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className={styles.modalContentCard}>
        <div className={styles.closeBtn}>
          <AiOutlineClose size={25} onClick={closeModal} />
        </div>
        <div className={styles.chat}>
          <div className={styles.warningBox}>
            <p>your chat history will be deleted when call ends</p>
            <p>using clt+enter to send new message</p>
          </div>
          {
            messages.map((msg, index) => (
              <div  className={styles.messages} key={index} ref={index === messages.length - 1 ? lastMessageRef : null}>
            {renderMessage(msg)}
              </div>
            ))
          }
          <div>{partnerIsTyping && <IsTyping />}</div>
        </div>
        <div className={styles.inputArea}>
        <AiOutlinePaperClip
              size={35}
              color="black"
              className={styles.attachIcon}
              onClick={() => fileInputRef.current.click()} // Trigger file input on icon click
              style={{marginTop:'10px',cursor:'pointer'}}
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept="image/*,.mp4,.mov,.avi,video/quicktime,audio/*,.mp3,.wav,.m4a"
              onChange={handleFileChange}
            />
          <textarea
            type="text"
            value={input}
            ref={inputRef}
            onChange={handleInputChange}
            placeholder="Write your message"
          />
         {input ? <button style={{backgroundColor:'#1860A5'}} onClick={sendMessage} ><IoIosArrowRoundForward size={25} color='white' /></button> : <button ><IoIosArrowRoundForward size={25} color='black' /></button> }
        </div>
      </div>
      
      {/* File Preview Modal */}   
        <FilePreview
        isOpen={filePreviewModalOpen}
        closeModal={closeFilePreviewModal}
        selectedFile={selectedFile}
        callManager={callManager}
        firsAppConfigTime={firsAppConfigTime}
        serverTimeStamp={serverTimeStamp}
        setMessages={setMessages}       
        uploadProgress={uploadProgress}
        setUploadProgress={setUploadProgress}        
      />
      
    </Modal>
  )
}
export default ChatArea