
import styles from  './photomessage.module.scss'
import { isPersianOrArabic } from "../../../utils/helperFunctions";
import { messageStatus } from "../../chat/Chat";
import CircularProgress from '../../CircularProgress/CircularProgress ';
import FileDownloading from '../../fileDownloadmodal/FileDownloading';
import { useState } from 'react';

const PhotoMessage = ({ msg, uploadProgress}) => {
  const [fileDownloadModalOpen, setFileDownloadModalOpen] = useState(false);
  const [selectedMediaUrl, setSelectedMediaUrl] = useState(null);  
      const handleImageClick = (msg) => {      
      if (msg.sender==='me') {               
        setSelectedMediaUrl(msg.originFile);  
      }else {
        setSelectedMediaUrl(msg.mediaUrl);  
      }    
      setFileDownloadModalOpen(true); 
  };
  const closeFileDownloadModal = () => {
  setFileDownloadModalOpen(false)
  };
    return (
      <>
      <div className={`${styles.message} ${msg.sender === 'me' ? styles.myMessage : styles.partnerMessage}`} style={{ textAlign: isPersianOrArabic(msg.text) ? 'right' : 'left' }}  >
        {msg.status==='pending'? <div className={styles.imageContainer}> 
        <img src={msg.mediaUrl} alt="Sent" className={styles.photo} style={{borderRadius:'20px', marginTop:'10px'}} onClick={() => handleImageClick(msg)}  />
        <CircularProgress progress={uploadProgress} />
        </div>:<img src={msg.mediaUrl} alt="Sent" className={styles.photo} style={{borderRadius:'20px', marginTop:'10px',cursor:'pointer'}}  onClick={() => handleImageClick(msg)}/>}
      
        {msg.text ? msg.text.split('\n').map((line, index) => (
          <span key={index}>{line}</span>
        )):''}
        <p>{msg.showTime} <strong>{msg.sender === 'me' && messageStatus(msg.status)}</strong></p>
      </div>
      <FileDownloading isOpen={fileDownloadModalOpen} closeModal={closeFileDownloadModal}  mediaurl={selectedMediaUrl}  type="photo" />
      </>
    );
  };
  
  export default PhotoMessage;