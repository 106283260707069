import React, { useState } from 'react';
import Modal from '../modal/Modal';
import { AiOutlineClose} from 'react-icons/ai';
import { IoIosArrowRoundForward } from "react-icons/io";
import styles from './filepreview.module.scss';
import { chunkFile, filedetection, formatTime, generateThumbnails, sendChunksToUrls, sendingMediaChatMessage } from '../../utils/helperFunctions';
import { DeleteUploadedFile, finishUploading, getPreSignUrl, HttpPostReq, updateData, UploadReq } from '../../services/requests';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
const CHUNK_SIZE = 5* 1024 * 1024; // 10 MB in bytes
const FilePreview = ({ isOpen, closeModal, selectedFile,callManager,firsAppConfigTime,serverTimeStamp,setMessages,setUploadProgress}) => {
   const { partner_account_id, accountId} = useSelector(state => state.auth);
  const [caption, setCaption] = useState('');
  const handleInputChange = (e) => {
    setCaption(e.target.value)    
  }
     const sendChunkCountToServer = async (file_name, part_count) => {
        try {
          // Define query parameters
          const params = { file_name, part_count };
    
          // Send GET request with query parameters
          const response = await getPreSignUrl('/v1/presigned-url-and-initiate', params);
            
          if (response.status === 200) {
            // console.log('Chunk count sent successfully:', response.data);
            return response.data          
          } else {
            throw new Error('Failed to send chunk count to server');
          }
        } catch (error) {
          console.error('Error sending chunk count:', error);
        }
      };

    const handleSend = async () => {
      const chunksCount= Math.ceil(selectedFile.size / CHUNK_SIZE); 
      const filename = selectedFile.name; 
      const fileType = selectedFile.type;
      const fileDetection=filedetection(selectedFile)
      let mediaurl;      
      // console.log('file type',fileDetection);
      closeModal()
      const currentTime = Date.now();
      const uuid = uuidv4();
      const sendingTimeStamp = (Number(currentTime) - Number(firsAppConfigTime)) + Number(serverTimeStamp);
      let thumnailKey='';
      let fileKey=''
      let uploadId='';
       let uploadStatus='thumnailSending';
      let blurredMediaUrl='';
        // Update messages immediately with a "pending" status
  
        if (uploadStatus==='thumnailSending') {
          try {
                // const {blurredThumbnailByteArray,regularThumbnailByteArray}  = await generateThumbnails(selectedFile); 
                if (fileDetection==='PHOTO' || fileDetection==='VIDEO') {
                  const {blurredThumbnailByteArray,regularThumbnailByteArray}  = await generateThumbnails(selectedFile); 
                  const regularThumbnailBlob = new Blob([regularThumbnailByteArray], { type: selectedFile.type });
                  const regularBlurredThumbnailBlob = new Blob([blurredThumbnailByteArray], { type: selectedFile.type });               
                  if (fileDetection==='PHOTO') {
                    mediaurl = URL.createObjectURL(regularThumbnailBlob);
                    blurredMediaUrl=URL.createObjectURL(regularBlurredThumbnailBlob);
                  }
                  if (fileDetection==='VIDEO') {
                    mediaurl = URL.createObjectURL(selectedFile);
                    blurredMediaUrl=URL.createObjectURL(regularBlurredThumbnailBlob);
                  }
                  setMessages(prevMessages => [
                    ...prevMessages,
                    {
                      type: fileDetection, 
                      text: `${caption}`, 
                      sender: 'me', 
                      timestamp: sendingTimeStamp, 
                      showTime: formatTime(sendingTimeStamp), 
                      uuid: uuid, 
                      status: 'pending',
                      mediaUrl: blurredMediaUrl,
                      originFile:URL.createObjectURL(selectedFile)               // mediaUrl is empty for now, will be updated after upload
                    }
                  ]);
                    // Send chunk count and filename to the server as query parameters
                  const getPresignUrl=await sendChunkCountToServer('thumnail.jpg', 1);
                  thumnailKey=getPresignUrl.file_name;
                  if (getPresignUrl) {
                    updateData(getPresignUrl.urls[0],blurredThumbnailByteArray);             
                  }  
                }else {
                  // console.log('voice');                  
                  setMessages(prevMessages => [
                    ...prevMessages,
                    {
                      type: fileDetection, 
                      text: `${caption}`, 
                      sender: 'me', 
                      timestamp: sendingTimeStamp, 
                      showTime: formatTime(sendingTimeStamp), 
                      uuid: uuid, 
                      status: 'pending',
                      mediaUrl: URL.createObjectURL(selectedFile) ,
                      originFile:URL.createObjectURL(selectedFile)                // mediaUrl is empty for now, will be updated after upload
                    }
                  ])
                }
                             
                uploadStatus='fileSending'             
            } catch (error) {
              console.error('Error generating thumbnail:', error);    
              setMessages(prevMessages =>
                prevMessages.filter(msg => msg.uuid !== uuid))         
            }
            if (uploadStatus==='fileSending') {
             
              try {
                const getFilePresignUrls=await sendChunkCountToServer(filename, chunksCount);
                fileKey=getFilePresignUrls.file_name;
                uploadId=getFilePresignUrls.upload_id
                 const chunks = await chunkFile(selectedFile, CHUNK_SIZE);
                 // Track the upload progress
                const handleProgress = (percentComplete) => {
                // console.log(`Upload is ${percentComplete}% complete`);
                // You can update the state here to reflect the progress in the UI
                setUploadProgress(percentComplete); // Example state update
                };
                // Call the function to send each chunk to its respective URL and get the etags
                sendChunksToUrls(getFilePresignUrls.urls, chunks,handleProgress)
                .then(etags => {
                  // console.log('etags',etags.length);                  
                  const data = {
                    is_multi_part:chunksCount>1, 
                    file_name: fileKey, 
                    thumbnail_name:thumnailKey,
                    upload_id:uploadId, 
                    parts: etags
                  };
                  if (etags.length>0) {
                    finishUploading('/v1/complete-multipart-upload',data).then(res=>{
                      setMessages(prevMessages => prevMessages.map(msg => 
                        msg.uuid === uuid ? { ...msg, status: 'sent', mediaUrl: mediaurl } : msg
                      ));
                      const messageBase64 =sendingMediaChatMessage(accountId,partner_account_id,firsAppConfigTime,serverTimeStamp,'',setMessages,filedetection(selectedFile),0,selectedFile.size,caption,fileKey,thumnailKey,filename,mediaurl)
                      setCaption('')
                      callManager.sendMessage(messageBase64)
                     
                      setCaption('')
                      // DeleteUploadedFile('/v1/delete-file',{key:thumnailKey})
                      // DeleteUploadedFile('/v1/delete-file',{key:fileKey})  
                     }
                     ).catch(error=>{
                      console.log('error in post request');                    
                     })
                  }else {
                    //delete pending messag from message
                    setMessages(prevMessages =>
                      prevMessages.filter(msg => msg.uuid !== uuid) // Remove the message with the matching uuid
                    );
                  }
                   
                })
              .catch(error => {
                console.error('An error occurred during the upload process 99999999:', error);
                 //delete pending messag from message
                 setMessages(prevMessages =>
                  prevMessages.filter(msg => msg.uuid !== uuid) // Remove the message with the matching uuid
                );
              });

              } catch (error) {
                console.error('Error reading file:', error);
              }
              
            }
        }
       
      };
  return (
    <Modal isOpen={isOpen} closeModal={closeModal} className={styles.filePreviewModal}>
      <div className={styles.filePreviewModal}>
        <div className={styles.closeBtn}>
          <AiOutlineClose size={25} onClick={closeModal} />
        </div>
        <div className={styles.filePreview}>
          {selectedFile?.type.startsWith('image/') ? (
            <img src={URL.createObjectURL(selectedFile)} alt="Selected" className={styles.previewImage} width={400} height={400} />
          ) : selectedFile?.type.startsWith('video/') ? (
            <video controls className={styles.previewVideo}>
              <source src={URL.createObjectURL(selectedFile)} />
            </video>
          ) : selectedFile?.type.startsWith('audio/') && <audio controls>
          <source src={URL.createObjectURL(selectedFile)} type="audio/ogg"/>
          
          Your browser does not support the audio tag.
        </audio> }
        </div>
        <div className={styles.inputArea}>
        <textarea
            type="text"
            value={caption}
            onChange={handleInputChange}
            placeholder="Write your message"
          />
          <button  onClick={handleSend}><IoIosArrowRoundForward size={25} color='#fff'/></button>
          {/* <button onClick={handleSend} >Send</button> */}
        </div>
        
        {/* {thumbnailUrl && <img src={thumbnailUrl} alt="Selected" className={styles.previewImage} width={400} height={400} /> }  */}
      </div>
    </Modal>
  );
};

export default FilePreview;